enum AuthAction {
  ChangePassword = 'change-password',
  ConfirmEmail = 'confirm-email',
  ResetPassword = 'reset-password',
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  TinkoffIdCompleteAuthFlow = 'tinkoff-id-complete-auth-flow',
  VerifyEmail = 'verify-email',
  YandexIdCompleteAuthFlow = 'yandex-id-complete-auth-flow'
}

const { ChangePassword, ConfirmEmail, ResetPassword, SignIn, SignUp, VerifyEmail } = AuthAction

const nameMapping = new Map([
  [ChangePassword, 'Изменение пароля'],
  [ConfirmEmail, 'Подтверждение email'],
  [ResetPassword, 'Сброс пароля'],
  [SignIn, 'Вход'],
  [SignUp, 'Регистрация'],
  [VerifyEmail, 'Подтверждение email']
])

namespace AuthAction {
  export function getName (authAction: AuthAction): string {
    return nameMapping.get(authAction)!
  }
}

export default AuthAction
