<script lang="ts" setup>
import type { NuxtError } from '#app'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import BasicInput from '~/components/form-elements/BasicInput.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import constants from '~/constants'
import AuthAction from '~/enums/auth-action'
import Page from '~/enums/page'
import ApiErrorHandler from '~/helpers/api-error-handler'
import JsonApiHelper from '~/helpers/json-api-helper'
import MindboxApiService from '~/services/mindbox-api-service'
import RetailRocketApiService from '~/services/retail-rocket-api-service'
import { useAuthStore } from '~/store/auth'
import { useProductsStore } from '~/store/products'
import { useSpecialistsStore } from '~/store/specialists'
import { useUserStore } from '~/store/user'

const model = defineModel<boolean>()

const props = withDefaults(
  defineProps<{
    action: AuthAction
    needToRedirectToAccountPage?: boolean
    token?: string | null
  }>(),
  { needToRedirectToAccountPage: false, token: null }
)

const emit = defineEmits<{
  (e: 'userSignedIn', userData: any): void
  (e: 'userSignedUp'): void
}>()

const emptyForm = { email: null, newPassword: null, newPassword2: null, password: null }
const messages = {
  emailFieldCannotBeEmpty: 'Поле «Email» не может быть пустым.',
  emailIsConfirmed: 'Email подтверждён.',
  failedToConfirmEmail: 'Не удалось подтвердить email.',
  failedToLogIn: 'Не удалось войти.',
  failedToRegister: 'Не удалось зарегистрироваться.',
  failedToRequestPasswordReset: 'Не удалось запросить сброс пароля.',
  failedToSetNewPassword: 'Не удалось установить новый пароль.',
  loginIsCompleted: 'Вход выполнен.',
  passwordFieldCannotBeEmpty: 'Поле «Пароль» не может быть пустым.',
  passwordsDoNotMatch: 'Пароли не совпадают.',
  passwordResetRequested: 'Сброс пароля запрошен.',
  registrationIsCompleted: 'Регистрация выполнена.',
  newPasswordFieldCannotBeEmpty: 'Поле «Новый пароль» не может быть пустым.',
  newPassword2FieldCannotBeEmpty: 'Поле «Повтор нового пароля» не может быть пустым.',
  newPasswordIsSet: 'Новый пароль установлен.'
}

const { $apiHelper, $toast } = useNuxtApp()
const router = useRouter()
const authStore = useAuthStore()
const productsStore = useProductsStore()
const specialistsStore = useSpecialistsStore()
const userStore = useUserStore()

const currentAction = ref<AuthAction | null>(null)
const emailConfirmationResult = ref(false)
const emailConfirmationToken = ref<string | null>(null)
const form = ref({ ...emptyForm })
const isDataLoading = ref(false)
const isLoading = ref(false)
const passwordResetToken = ref<string | null>(null)

async function beforeOpen (): Promise<void> {
  const { action, token } = props
  const { ChangePassword, VerifyEmail } = AuthAction

  currentAction.value = action as AuthAction
  form.value = { ...emptyForm }

  switch (action) {
    case ChangePassword:
      passwordResetToken.value = token as string
      break
    case VerifyEmail: {
      emailConfirmationToken.value = token as string

      await verifyEmail()
      break
    }
  }
}

function close (): void {
  model.value = false
}

async function resetPassword (): Promise<void> {
  const { email } = form.value
  const { emailFieldCannotBeEmpty, failedToRequestPasswordReset, passwordResetRequested } = messages

  if (!email) {
    $toast.error(emailFieldCannotBeEmpty)

    return
  }

  isLoading.value = true

  try {
    await $apiHelper.auth.resetPassword(email)

    $toast.success(passwordResetRequested)

    close()
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as NuxtError, failedToRequestPasswordReset)
  }

  isLoading.value = false
}

async function changePassword (): Promise<void> {
  const { newPassword, newPassword2 } = form.value
  const {
    newPasswordFieldCannotBeEmpty,
    newPassword2FieldCannotBeEmpty,
    passwordsDoNotMatch,

    failedToSetNewPassword,
    newPasswordIsSet
  } = messages

  if (!newPassword) {
    $toast.error(newPasswordFieldCannotBeEmpty)

    return
  }

  if (!newPassword2) {
    $toast.error(newPassword2FieldCannotBeEmpty)

    return
  }

  if (newPassword !== newPassword2) {
    $toast.error(passwordsDoNotMatch)

    return
  }

  isLoading.value = true

  try {
    await $apiHelper.auth.changePassword(newPassword, passwordResetToken.value as string)

    $toast.success(newPasswordIsSet)

    currentAction.value = AuthAction.SignIn
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as NuxtError, failedToSetNewPassword)
  }

  isLoading.value = false
}

async function signIn (): Promise<void> {
  const { email, password } = form.value
  const {
    emailFieldCannotBeEmpty,
    passwordFieldCannotBeEmpty,

    failedToLogIn,
    loginIsCompleted
  } = messages

  if (!email) {
    $toast.error(emailFieldCannotBeEmpty)

    return
  }

  if (!password) {
    $toast.error(passwordFieldCannotBeEmpty)

    return
  }

  const { cookieOptions } = constants

  isLoading.value = true

  try {
    const { meta } = await $apiHelper.auth.getToken(email, password) as any
    const { phoneNumber, token, userId } = meta

    useCookie('token', cookieOptions).value = token
    useCookie('userId', cookieOptions).value = userId
    useCookie('guestId').value = null

    authStore.setToken(token)
    authStore.setUserId(userId)
    authStore.setGuestId(null)

    $toast.success(loginIsCompleted)

    MindboxApiService.authorizeCustomer(userId, email, phoneNumber)

    RetailRocketApiService.setEmail(email)

    close()

    if (props.needToRedirectToAccountPage) {
      await router.push(Page.Account)
    }
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as NuxtError, failedToLogIn)
  }

  isLoading.value = false

  if (!authStore.isUser) {
    return
  }

  const params = { include: 'cartProducts' }

  try {
    const userResponse = await $apiHelper.users.getUser(authStore.userId as string, params)
    const { data } = JsonApiHelper.denormalizeResponse(userResponse) as any

    if (data) {
      userStore.updateFromUserData(data)

      emit('userSignedIn', data)
    }

    if (data?.meta) {
      const { cartTotalItems, wishListTotalItems } = data.meta

      if (Number.isInteger(cartTotalItems) && Number.isInteger(wishListTotalItems)) {
        productsStore.setCartTotalItems(cartTotalItems)
        productsStore.setWishListTotalItems(wishListTotalItems)
      }

      const cartProducts = data.relationships.cartProducts.data

      for (const { id, meta } of cartProducts) {
        productsStore.addCartProduct({ id, quantity: meta.quantity })
      }
    }
  } catch (error) {
    console.error(error)
  }
}

async function signUp (): Promise<void> {
  const { email, password } = form.value
  const {
    emailFieldCannotBeEmpty,
    passwordFieldCannotBeEmpty,

    failedToRegister,
    registrationIsCompleted
  } = messages

  if (!email) {
    $toast.error(emailFieldCannotBeEmpty)

    return
  }

  if (!password) {
    $toast.error(passwordFieldCannotBeEmpty)

    return
  }

  isLoading.value = true

  try {
    await $apiHelper.auth.register(email, password)

    emit('userSignedUp')

    $toast.success(registrationIsCompleted)

    RetailRocketApiService.setEmail(email)

    currentAction.value = AuthAction.ConfirmEmail
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as NuxtError, failedToRegister)
  }

  isLoading.value = false
}

async function verifyEmail (): Promise<void> {
  const { emailIsConfirmed, failedToConfirmEmail } = messages

  isDataLoading.value = true

  try {
    await $apiHelper.auth.verifyEmail(emailConfirmationToken.value as string)

    $toast.success(emailIsConfirmed)

    emailConfirmationResult.value = true
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as NuxtError, failedToConfirmEmail)
  }

  isDataLoading.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model" @before-open="beforeOpen">
    <template v-if="currentAction !== null" #title>
      {{ AuthAction.getName(currentAction) }}
    </template>

    <template v-if="currentAction === AuthAction.ChangePassword">
      <Notification class="mb-6" is-small status="info">
        Придумайте новый пароль.
      </Notification>

      <BasicInput v-model="form.newPassword" class="mb-6" is-password label-text="Новый пароль" />

      <BasicInput v-model="form.newPassword2" class="mb-6" is-password label-text="Повтор нового пароля" />

      <BasicButton color="green" :is-loading="isLoading" title="Изменить пароль" @click="changePassword" />
    </template>
    <template v-else-if="currentAction === AuthAction.ConfirmEmail">
      <Notification class="mb-6" is-small status="success">
        Регистрация прошла успешно.
      </Notification>

      <p>Теперь необходимо подтвердить email, чтобы войти в&nbsp;личный кабинет. Через несколько минут на&nbsp;ваш почтовый ящик придёт письмо со&nbsp;ссылкой на&nbsp;подтверждение.</p>
    </template>
    <template v-else-if="currentAction === AuthAction.ResetPassword">
      <Notification class="mb-6" is-small status="info">
        Введите свой email, и&nbsp;мы&nbsp;вышлем вам инструкции по&nbsp;сбросу пароля.
      </Notification>

      <BasicInput v-model="form.email" class="mb-6" label-text="Email" />

      <BasicButton color="green" :is-loading="isLoading" title="Сбросить пароль" @click="resetPassword" />
    </template>
    <template v-else-if="currentAction === AuthAction.SignIn">
      <BasicInput v-model="form.email" class="mb-6" label-text="Email" />

      <BasicInput v-model="form.password" class="mb-2" is-password label-text="Пароль" />

      <div class="mb-6 text-right">
        <a href="#" @click.prevent="currentAction = AuthAction.ResetPassword">Сбросить пароль</a>
      </div>

      <BasicButton class="mb-4" color="green" :is-loading="isLoading" title="Войти" @click="signIn" />

      <AuthProviders class="mb-6" />

      <Agreement
        :button-titles="['Войти', 'Войти c\xA0помощью…']"
        class="mb-0"
        need-to-agree-with-data-protection-policy
      />
    </template>
    <template v-else-if="currentAction === AuthAction.SignUp">
      <BasicInput v-model="form.email" class="mb-6" label-text="Email" />

      <BasicInput v-model="form.password" class="mb-6" is-password label-text="Пароль" />

      <BasicButton
        class="mb-4"
        color="green"
        :is-loading="isLoading"
        title="Зарегистрироваться"
        @click="signUp"
      />

      <AuthProviders class="mb-6" />

      <Agreement
        :button-titles="['Зарегистрироваться', 'Войти c\xA0помощью…']"
        class="mb-0"
        need-to-agree-with-data-protection-policy
      />
    </template>
    <template v-else-if="currentAction === AuthAction.VerifyEmail">
      <Preloader v-if="isDataLoading" />
      <template v-else>
        <Notification v-if="emailConfirmationResult" is-small status="success">
          {{ messages.emailIsConfirmed }}
        </Notification>
        <Notification v-else is-small status="info">
          Скорее всего, ссылка для подтверждения была использована ранее.
        </Notification>
      </template>
    </template>

    <template
      v-if="currentAction !== null && [
        AuthAction.ConfirmEmail,
        AuthAction.ResetPassword,
        AuthAction.SignIn,
        AuthAction.SignUp,
        AuthAction.VerifyEmail
      ].includes(currentAction)"
      #action
    >
      <a
        v-if="[
          AuthAction.ConfirmEmail,
          AuthAction.ResetPassword,
          AuthAction.SignUp,
          AuthAction.VerifyEmail
        ].includes(currentAction)"
        href="#"
        @click.prevent="currentAction = AuthAction.SignIn"
      >Войти</a>
      <a
        v-else-if="currentAction === AuthAction.SignIn"
        href="#"
        @click.prevent="currentAction = AuthAction.SignUp"
      >Зарегистрироваться</a>
    </template>
  </CustomModalWindow>
</template>
