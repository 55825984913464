enum AuthProvider {
  TinkoffId = 'tinkoffId',
  VkId = 'vkId',
  YandexId = 'yandexId'
}

const { TinkoffId, VkId, YandexId } = AuthProvider

const iconNameMapping = new Map([
  [TinkoffId, 'auth-providers/tinkoff-id'],
  [VkId, 'auth-providers/vk-id'],
  [YandexId, 'auth-providers/yandex-id']
])

namespace AuthProvider {
  export const sortedMembers = [VkId, YandexId, TinkoffId]

  export function getIconName (authProvider: AuthProvider): string {
    return iconNameMapping.get(authProvider)!
  }
}

export default AuthProvider
